import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
  }

  radioChanged(ev) {
    if (ev.target.value == 'false' ) {
      this.buttonTargets.forEach((el, i) => {
        if (el.value == 'false'){
          el.checked = true;
        }
      })
    }
  }
}