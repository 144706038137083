import { Controller } from 'stimulus';
import { useDebounce, useMemo, useTransition } from 'stimulus-use';

import { useFloatingPopups } from '../mixins';
import { controlledFetch } from '../../lib';

import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static debounces = [
    'performSearch', 'onNextFocusItem', 'onPreviousFocusItem', 'onScroll'
  ];

  static targets = [
    'limitMessage', 'input', 'template', 'option', 'pool', 'popup', 'placeholder', 'newTemplate'
  ];

  static values = {
    allowNew: Boolean,
    focusedClass: String,
    limit: Number,
    selected: Array,
    newSelected: Array,
    newName: String,
    remoteUrl: String,
    visible: String,
  };

  get focusedOptions() {
    return Array.from(this.popupTarget.querySelectorAll('li[aria-selected="true"]'));
  }

  get options() {
    return Array.from(this.popupTarget.querySelectorAll('li'));
  }

  get remoteUrl() {
    return [...this.selectedIds].reduce(
      (url, id) => url += `&exclude[]=${id}`, `${this.remoteUrlValue}?name=${this.search}`
    );
  }

  connect() {
    this.selectedIds = new Set(Array.from(this.selectedValue.map(pair => pair[0])));
    this.focusedClassList = this.focusedClassValue.split(' ');
    this.focusedItemIndex = -1;

    useDebounce(this);
    useFloatingPopups(this);
    useMemo(this);
    useTransition(this, { element: this.popupTarget });

    this.selectedValue.forEach(([ value, label ]) => {
      this.addSelectedNode(label, value);
    });

    this.newSelectedValue.forEach(([ value, label ]) => {
      this.addNewSelectedNode(label, value);
    });

    this.updateLimit();

    this.listener = new ElementListener(this.scrollRoot, 'scroll', this.onScroll);

    requestAnimationFrame(() => {
      this.positionPopup();
    });
  }

  disconnect() {
    this.listener.remove();
  }

  addSelectedNode(label, value) {
    const node = this.templateTarget.content.cloneNode(true);

    node.querySelector('span').innerText = label;
    node.querySelector('button').value = value;
    node.querySelector('input').value = value;

    this.poolTarget.insertBefore(node, this.inputTarget);
  }

  addNewSelectedNode(label, value) {
    const node = this.templateTarget.content.cloneNode(true);

    node.querySelector('span').innerText = label;

    const input = node.querySelector('input');

    input.dataset.newEntity = true;
    input.name = this.newNameValue;
    input.value = label;

    this.poolTarget.insertBefore(node, this.inputTarget);
  }

  addNewOption(ev) {
    const { currentTarget: { dataset: { label, inputName } } } = ev;
    const node = this.templateTarget.content.cloneNode(true);

    node.querySelector('span').innerText = label;

    const input = node.querySelector('input');

    input.dataset.newEntity = true;
    input.name = inputName;
    input.value = label;

    this.poolTarget.insertBefore(node, this.inputTarget);
    this.updateLimit();
    this.close();
  }

  changeInput() {
    const { value } = this.inputTarget;

    if (this.hasPlaceholderTarget) {
      if (value.length > 0) {
        this.placeholderTarget.classList.add('opacity-0');
      } else {
        this.placeholderTarget.classList.remove('opacity-0');
      }
    }

    this.search = value.toString().trim().toLowerCase();
    this.performSearch();
  }

  async close() {
    this.focusedItemIndex = -1;
    this.inputTarget.value = '';

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.classList.remove('opacity-0');
    }

    await this.leave();

    // TODO this is not resetting scroll
    this.popupTarget.scrollTop = 0;
    this.positionPopup();
  }

  focusContainer() {
    this.inputTarget.focus();
  }

  hoverOption(ev) {
    if (this.scrolling !== undefined)
      return;

    const index = this.optionTargets.indexOf(ev.currentTarget);

    this.updateFocus(index);
  }

  keypressInput(ev) {
    if (ev.key === 'ArrowDown') {
      ev.preventDefault();

      this.onNextFocusItem();
    } else if (ev.key === 'ArrowUp') {
      ev.preventDefault();

      this.onPreviousFocusItem();
    } else if (ev.key === 'Enter') {
      ev.preventDefault();

      this.onFocusSelected(ev);
    } else if (ev.key === 'Backspace' && ev.currentTarget.value.length === 0) {
      ev.preventDefault();

      this.onBackspaceRemove();
    } else if (ev.key === 'Escape') {
      this.inputTarget.blur();
    }
  }

  updateLimit() {
    const totalSize = this.selectedIds.size +
      this.poolTarget.querySelectorAll('input[data-new-entity]').length;

    if (totalSize <= this.limitValue) {
      this.inputTarget.disabled = false;
      this.limitMessageTarget.classList.add('hidden');
    } else {
      this.inputTarget.disabled = true;
      this.limitMessageTarget.classList.remove('hidden');
    }
  }

  async performSearch() {
    this.updateFocus(-1);

    if (this.ongoingFetchController !== undefined) {
      this.ongoingFetchController.abort();
      this.ongoingFetchController = undefined;
    }

    if (this.search === '') {
      this.close();
      return;
    }

    const [ promise, controller ] = controlledFetch(this.remoteUrl, {
      credentials: 'same-origin', headers: {
        'Accept': 'text/html, application/xhtml+xml',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    this.ongoingFetchController = controller;

    try {
      const response = await promise;
      const text = await response.text();

      if (text.length < 1) {
        if (this.allowNewValue) {
          const newNode = this.newTemplateTarget.content.cloneNode(true);

          newNode.querySelector('span strong').innerText = this.inputTarget.value;
          newNode.querySelector('li').dataset.label = this.inputTarget.value;

          this.popupTarget.innerHTML = '';
          this.popupTarget.appendChild(newNode);
          this.enter();

        } else {
          this.leave();
        }
      } else {
        this.popupTarget.innerHTML = text;
        this.enter();
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async removeOption(ev) {
    ev.preventDefault();

    await this.close();

    if (ev.currentTarget) {
      this.selectedIds.delete(+ev.currentTarget.value);

      ev.currentTarget.parentElement.remove();

      this.updateLimit();
      this.positionPopup();
    }
  }

  async selectOption(ev) {
    ev.preventDefault();

    const { dataset: { value } } = ev.currentTarget;

    if (value !== '' || value !== null) {
      const id = +value;

      this.selectedIds.add(id);
      this.addSelectedNode(ev.currentTarget.dataset.label, id);
      this.updateLimit();

      await this.close();
    }
  }

  updateFocus(newIndex, scrollTo = false) {
    this.focusedOptions.forEach(li => {
      li.classList.remove(...this.focusedClassList);
      li.removeAttribute('aria-selected');
    });

    this.focusedItemIndex = newIndex;

    if (this.focusedItemIndex === -1)
      return;

    const node = this.optionTargets[this.focusedItemIndex];

    if (!node)
      return;

    node.classList.add(...this.focusedClassList);
    node.setAttribute('aria-selected', 'true');

    this.popupTarget.setAttribute('aria-activedescendant', node.id);

    if (scrollTo) {
      node.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

      if (this.scrolling)
        clearTimeout(this.scrolling);

      this.scrolling = setTimeout(() => {
        this.scrolling = undefined;
      }, 250);
    }
  }

  async onBackspaceRemove() {
    await this.close();

    if (this.selectedIds.size > 0) {
      this.selectedIds.delete([...this.selectedIds].pop());
      this.poolTarget.querySelector('div:last-of-type').remove();

      this.updateLimit();
      this.positionPopup();
    }
  }

  async onFocusSelected(ev) {
    const option = this.optionTargets[this.focusedItemIndex];

    if (option) {
      const id = +option.dataset.value;

      this.selectedIds.add(id);
      this.addSelectedNode(option.dataset.label, id);
      this.updateLimit();

      await this.close();
    }
  }

  onNextFocusItem = () => {
    this.updateFocus(this.focusedItemIndex + 1, true);
  }

  onPreviousFocusItem = () => {
    this.updateFocus(this.focusedItemIndex - 1, true);
  }

  onScroll = () => {
    this.positionPopup();
  }
}
