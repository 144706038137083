import { Controller } from 'stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['copyInput'];

  connect() {
    useMutation(this, { childList: true, subtree: true })
  }

  mutate(entries) {
    for (const mutation of entries) {
      for (const node of mutation.addedNodes) {
        if (node.tagName == 'DIV') {
          if (node.querySelector('input') !== null) {
            this.copyInputTargets.forEach((copyInput) => {
              const inputNode = node.querySelector('input')
              const clonedNode = inputNode.cloneNode(true)
              copyInput.appendChild(clonedNode)
            })
          }
        }
      }

      for (const node of mutation.removedNodes) {
        if (node.tagName == 'DIV') {
          if (node.querySelector('input') !== null) {
            this.copyInputTargets.forEach((copyInput) => {
              const inputNode = node.querySelector('input')
              
              copyInput.querySelector(`input[name="${inputNode.name}"]`).remove();
            })
          }
        }
      }
    }
  }
}